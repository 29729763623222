<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md12 v-if="!isNew">
        <v-card style="background-color: white" color="#31708E">
          <v-data-table :headers="headers" :items="events">
            <template slot="headerCell" slot-scope="{ header }">
              <span class="font-weight-light text-warning text--darken-3" v-text="header.text" />
            </template>

            <template slot="item" slot-scope="{ item }">
              <tr>
                <td style="width: 20%">{{ item.name }}</td>
                <td style="width: 15%">{{ item.start }}</td>
                <td style="width: 15%">{{ item.end }}</td>
                <td style="width: 30%">{{ item.description }}</td>
                <td style="width: 250px">
                  <v-select :items="actions" v-model="item.actions" @change="action(item)" label="Choose Action"></v-select>
                </td>
              </tr>
              <!-- <td class="text-xs-left">{{ item.name }}</td>
            <td class="text-xs-left">{{ item.start }}</td>
            <td class="text-xs-left">{{ item.end }}</td>
            <td class="text-xs-left">{{ item.description }}</td>
            <td class="text-xs-left" style="width:150px">
              <v-select
                :items="actions"
                v-model="eventAction"
                @change="action(item)"
                label="Choose Action"
              ></v-select>
              <v-dialog v-model="showRemoveeventDialog" width="500">
                <v-card style="background-color:white">
                  <v-card-title class="headline grey lighten-2" primary-title>{{eventAction}} Event</v-card-title>
                  <v-card-text>Please confirm event {{lower(eventAction)}} action.</v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="danger" @click="doAction(item)">{{upper(eventAction)}}</v-btn>
                    <v-btn color="secondary" text @click="cancelAction(eventAction)">Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              </td>-->
            </template>
          </v-data-table>
          <v-dialog v-model="showRemoveeventDialog" width="500" scrollable>
            <v-card :loading="doProccess" :disabled="doProccess">
              <v-card-title class="headline grey lighten-2" primary-title
                >{{ eventAction }} {{ this.activeitem ? this.activeitem.name : "Event" }}</v-card-title
              >
              <v-card-text>Please confirm event {{ lower(eventAction) }} action.</v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="danger" :loading="doProccess" @click="doAction(activeitem)">{{ upper(eventAction) }}</v-btn>
                <v-btn color="secondary" text @click="cancelAction(eventAction)">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-flex xs12 text-xs-right>
            <v-btn class="mx-2" @click="newEvent()" fab dark color="#5085A5">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </v-flex>
        </v-card>
      </v-flex>

      <v-flex xs12 md12 v-if="isNew">
        <v-card style="background-color: white" color="#31708E" title="Manage Contacts" :loading="doProccess" :disabled="doProccess">
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex xs12 md6>
                  <v-text-field
                    label="Name"
                    v-if="eventAction === 'Edit' || (isNew && eventAction === '')"
                    v-model="newevent.name"
                    class="purple-input"
                  />
                  <v-text-field label="Name" v-else disabled="disabled" v-model="newevent.name" class="purple-input" />
                </v-flex>

                <v-flex xs12 md6>
                  <v-menu
                    dark
                    v-if="eventAction === 'Edit' || (isNew && eventAction === '')"
                    ref="startMenu"
                    v-model="startMenu"
                    :close-on-content-click="false"
                    :return-value.sync="newevent.start"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="newevent.start" label="Start" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker
                      @input="
                        startMenu = false;
                        $refs.startMenu.save(newevent.start);
                      "
                      v-model="newevent.start"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>

                  <v-text-field label="Start" v-model="newevent.start" v-else :disabled="'disabled'" class="purple-input" />
                </v-flex>
                <v-flex xs12 md6>
                  <v-menu
                    dark
                    v-if="eventAction === 'Edit' || (isNew && eventAction === '')"
                    ref="endMenu"
                    v-model="endMenu"
                    :close-on-content-click="false"
                    :return-value.sync="newevent.end"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="newevent.end" label="End" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker
                      @input="
                        endMenu = false;
                        $refs.endMenu.save(newevent.end);
                      "
                      v-model="newevent.end"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>
                  <v-text-field label="End" :disabled="'disabled'" v-model="newevent.end" v-else class="purple-input" />
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    label="Description"
                    v-if="eventAction === 'Edit' || (isNew && eventAction === '')"
                    v-model="newevent.description"
                    class="purple-input"
                  />
                  <v-text-field label="Description" :disabled="'disabled'" v-model="newevent.description" v-else class="purple-input" />
                </v-flex>
                <v-flex xs12 text-xs-right>
                  <v-btn class="mx-0 mr-2 font-weight-light" @click="cancelAction()" color="primary">Cancel</v-btn>
                  <v-btn class="mx-0 font-weight-light" :loading="doProccess" @click="create()" color="#5085A5">{{
                    eventAction == "Edit" ? "Update event " : eventAction == "Reset Password" ? "Reset Password" : "Create event "
                  }}</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import eventService from "@/services/eventService";
import Vue from "vue";
import IdGenerator from "../../../shared/extensions/IdGenerator";

export default {
  //
  data() {
    return {
      startMenu: false,
      endMenu: false,
      color: null,
      colors: ["purple", "info", "#5085A5", "warning", "error"],
      top: true,
      bottom: false,
      left: false,
      right: false,
      actions: ["Edit", "Remove"],
      showPassword1: false,
      eventAction: "",
      activeitem: null,
      showPassword2: false,
      showRemoveeventDialog: false,
      doProccess: false,
      password: "",
      isNew: false,
      confirmPassword: "",
      newevent: {
        id: null,
        name: "",
        start: "",
        end: "",
        description: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => "The email and password you entered don't match",
      },
      headers: [
        {
          sortable: false,
          text: "Name",
          value: "name",
        },
        {
          sortable: false,
          text: "Start",
          value: "start",
          align: "left",
        },
        {
          sortable: false,
          text: "End",
          value: "end",
          align: "left",
        },
        {
          sortable: false,
          text: "Description",
          value: "description",
          align: "left",
        },
        {
          sortable: false,
          text: "Actions",
          value: "actions",
          align: "left",
        },
      ],
      events: [],
      tabs: 0,
      list: {
        0: false,
        1: false,
        2: false,
      },
    };
  },
  watch: {
    showRemoveeventDialog: function (val) {
      if (!val) {
        this.activeitem.actions = "";
      }
    },
  },
  mounted() {
    this.loadevents();
  },
  methods: {
    newEvent() {
      this.newevent = {
        name: "",
        start: "",
        end: "",
        description: "",
        id: null,
      };
      this.isNew = true;
    },
    async create() {
      this.doProccess = true;
      var data = {
        name: this.newevent.name,
        start: this.newevent.start,
        end: this.newevent.end,
        description: this.newevent.description,
        id: this.newevent.id ? this.newevent.id : null,
        formId: null,
        rootFormId: null,
        options: {
          theme: null,
          contacts: [],
          contactGroups: [],
          effective: {
            anonymous: false,
            anyContact: false,
          },
        },
      };
      if (!data.id) {
        try {
          Vue.set(data, "companyId", this.companyId);
          data.id = IdGenerator.newId();
          await eventService.updateEventInfo(data);
          this.$notification.showSuccess("The event saved successfully.");
          this.loadevents();
          this.isNew = false;
          this.eventAction = "";
          this.doProccess = false;
        } catch (error) {
          this.$notification.showError(error.message);
          this.doProccess = false;
          return;
        }
      } else {
        try {
          await eventService.updateEventInfo(data);
          this.$notification.showSuccess("The event saved successfully.");
          this.doProccess = false;
          this.loadevents();
          this.isNew = false;
          this.eventAction = "";
        } catch (error) {
          this.$notification.showError(error.message);
          this.doProccess = false;
          return;
        }
      }
    },
    async loadevents() {
      this.events = [];
      const docs = await eventService.eventList(this.companyId);
      docs.forEach((doc) => {
        var u = doc.data();
        u.id = doc.id;
        u.actions = "";
        this.events.push(u);
      });
      this.$forceUpdate();
    },
    action(item) {
      this.activeitem = item;
      if (item.actions === "Remove") {
        this.eventAction = "Remove";
        this.showRemoveeventDialog = true;
      }
      if (item.actions === "Edit") {
        this.eventAction = "Edit";
        this.isNew = true;
        this.newevent = item;
      }
    },
    lower(item) {
      return (item + " ").toLowerCase();
    },
    async doAction(event) {
      try {
        if (event.actions == "Remove") {
          this.doProccess = true;
          await eventService.removeEvent(event);
          this.$notification.showSuccess("The event removed successfully.");
          this.doProccess = false;
          this.eventAction = "";
          event.Action = "";
          this.loadevents();
          this.showRemoveeventDialog = false;
        }
      } catch (error) {
        this.$notification.showError(error.message);
        this.doProccess = false;
      }
    },
    cancelAction(eventAction) {
      this.eventAction = "";
      this.loadevents();
      if (this.activeitem) this.activeitem.actions = "";

      this.isNew = false;
      this.eventAction = "";
      this.showRemoveeventDialog = false;
    },
    upper(item) {
      return (item + " ").toUpperCase();
    },
  },
  computed: {
    companyId() {
      return this.$store.getters.companyId;
    },
    userId() {
      return this.$store.getters.userId;
    },
  },
};
</script>
