import firebase from "../plugins/firebase-app";
import firebaseAdmin from "../plugins/firebase-admin";
import { Event } from "../models";

export default class EventService {
  private db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore;

  constructor(db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore) {
    this.db = db;
  }

  public async getById(id: string) {
    return await this.db.collection("Events").doc(id).get();
  }

  public async eventList(companyId: string) {
    return await this.db.collection("Events").where("companyId", "==", companyId).get();
  }

  public async removeEvent(event: Event) {
    return await this.db.collection("Events").doc(event.id).delete();
  }

  public async addEvent(event: Event) {
    return await this.db.collection("Events").add(event);
  }

  public async updateEventInfo(event: Event) {
    return await this.db.collection("Events").doc(event.id).set(event, { merge: true });
  }
}
